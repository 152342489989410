var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"w-100"},[_c('b-row',{staticClass:"card mb-0"},[_c('b-col',{attrs:{"cols":"12","md":"12","disabled":true}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":_vm.isCreatingNewSpecimen ? 12 : 6}},[_c('b-form-group',{attrs:{"label-class":"label-size","label":"ESTADO","label-for":"estado"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"id":"estado","clearable":false,"label":"description","options":_vm.arrayStatus,"reduce":function (option) { return option.id; },"placeholder":"Agregar estado","disabled":_vm.isInventory ? !_vm.specimen.check : false,"selectable":function (opt) { return opt.selectable; }},model:{value:(_vm.specimen.new_status_id),callback:function ($$v) {_vm.$set(_vm.specimen, "new_status_id", $$v)},expression:"specimen.new_status_id"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.specimen.new_status_id == 3 || _vm.specimen.new_is_reason == 1)?[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"MOTIVO","label-for":"input-1"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.arrayReasons,"clearable":false,"label":"reason","reduce":function (option) { return option.id; },"placeholder":"Seleccione...","disabled":_vm.isInventory ? !_vm.specimen.check : false},on:{"input":function () {
											_vm.arrayDeads[0].others_description = ''
										}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{style:(("margin-left: " + (option.parent_id ? '10' : '0') + "px"))},[_vm._v(" "+_vm._s(option.reason)+" ")]),(option.parent_id)?_c('span',[_vm._v(" Sub-motivo de "+_vm._s(option.parent_reason)+" ")]):_vm._e()])]}}],null,true),model:{value:(_vm.specimen.reason_id),callback:function ($$v) {_vm.$set(_vm.specimen, "reason_id", $$v)},expression:"specimen.reason_id"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2170425002)})],1),(_vm.specimen.reason === 19)?_c('b-form-group',{attrs:{"label":"OTROS","label-for":"others_description"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-input-group',{attrs:{"label-for":"others_description"}},[_c('b-form-textarea',{attrs:{"id":"others_description","type":"text","placeholder":"Ingrese otra razon","state":errors[0] ? false : null},model:{value:(_vm.arrayDeads[0].others_description),callback:function ($$v) {_vm.$set(_vm.arrayDeads[0], "others_description", $$v)},expression:"arrayDeads[0].others_description"}})],1),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2711383290)})],1):_vm._e()],1),(_vm.specimen.new_status_id == 3)?_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"EVIDENCIAS","label-for":"input-1"}},[_c('drag-and-drop-images',{attrs:{"filesArray":_vm.specimen.evidences,"source":"flyers","containerTitle":"Arrastra las evidencias aquí","containerDescription":"Selecciona uno","containerPadding":"10px"},model:{value:(_vm.specimen.evidences),callback:function ($$v) {_vm.$set(_vm.specimen, "evidences", $$v)},expression:"specimen.evidences"}})],1)],1):_vm._e()]:_vm._e(),(_vm.specimen.new_status_id == 6 || _vm.specimen.new_is_reason)?[_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.specimen.disease === 1)?_c('b-form-group',{attrs:{"label":"OTROS","label-for":"others_description"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-input-group',{attrs:{"label-for":"others_description"}},[_c('b-form-textarea',{attrs:{"id":"others_description","type":"text","placeholder":"Ingrese otra razon","state":errors[0] ? false : null},model:{value:(_vm.specimen.others_description),callback:function ($$v) {_vm.$set(_vm.specimen, "others_description", $$v)},expression:"specimen.others_description"}})],1),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1115887496)})],1):_vm._e()],1)]:_vm._e(),[(_vm.specimen.new_status_id == 9 || _vm.specimen.new_status_id == 10)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-class":"label-size","label":"PESO KG","label-for":"weight"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"£","label-for":"weight"}},[_c('b-form-input',{attrs:{"id":"weight","type":"number","placeholder":"0","state":errors[0] ? false : null,"disabled":_vm.isInventory ? !_vm.specimen.check : false},model:{value:(_vm.specimen.weight),callback:function ($$v) {_vm.$set(_vm.specimen, "weight", $$v)},expression:"specimen.weight"}})],1),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1817265667)})],1)],1):_vm._e(),([6, 9, 10, 12, 13].includes(_vm.specimen.new_status_id))?_c('b-col',{attrs:{"cols":"12","md":[9, 10].includes(_vm.specimen.new_status_id) ? '6' : '12'}},[_c('b-form-group',{attrs:{"label-class":"label-size","label":"RESPONSABLE","label-for":"responsable"}},[_c('ValidationProvider',{attrs:{"rules":_vm.specimen.new_status_id == 10 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"id":"responsable","clearable":false,"label":"name","options":_vm.arrayUsers,"reduce":function (option) { return option.id; },"placeholder":"Agregar responsable","disabled":_vm.isInventory ? !_vm.specimen.check : false},model:{value:(_vm.specimen.user_id),callback:function ($$v) {_vm.$set(_vm.specimen, "user_id", $$v)},expression:"specimen.user_id"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1706667253)})],1)],1):_vm._e()],([2, 4, 5, 11].includes(_vm.specimen.new_status_id))?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-class":"label-size","label":"CLIETE O BENEFICIARIO","label-for":"client"}},[_c('v-select',{attrs:{"id":"client","clearable":false,"label":"name","options":_vm.arrayClients,"reduce":function (option) { return option.id; },"disabled":_vm.isInventory ? !_vm.specimen.check : false},model:{value:(_vm.specimen.client_id),callback:function ($$v) {_vm.$set(_vm.specimen, "client_id", $$v)},expression:"specimen.client_id"}})],1)],1):_vm._e(),([2].includes(_vm.specimen.new_status_id))?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-class":"label-size","label":"PRECIO","label-for":"price"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price","label":"name","type":"text","placeholder":"0","state":errors[0] ? false : null,"disabled":_vm.isInventory ? !_vm.specimen.check : false},on:{"keyup":function($event){return _vm.justNumbersToFront(_vm.specimen)},"blur":function($event){return _vm.fixedCost(_vm.specimen)}},model:{value:(_vm.specimen.price),callback:function ($$v) {_vm.$set(_vm.specimen, "price", $$v)},expression:"specimen.price"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3324328389)})],1)],1):_vm._e(),(!_vm.isCreatingNewSpecimen || _vm.specimen.new_status_id !== 1)?[(_vm.specimen.status !== 3 && _vm.specimen.disease !== 1)?_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-class":"label-size","label":"DESCRIPCION","label-for":"description"}},[_c('b-input-group',{attrs:{"label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"description","type":"text","placeholder":"Agregar descripcion","disabled":_vm.isInventory ? !_vm.specimen.check : false},model:{value:(_vm.specimen.description),callback:function ($$v) {_vm.$set(_vm.specimen, "description", $$v)},expression:"specimen.description"}})],1)],1)],1):_vm._e()]:_vm._e(),_c('b-col',{attrs:{"cols":"12","md":_vm.isCreatingNewSpecimen ? 12 : 6}},[_c('b-form-group',{attrs:{"label-class":"label-size","label":"FECHA","label-for":"date"}},[_c('flat-pickr',{staticClass:"form-control bg-transparent",attrs:{"id":"date","config":{
								locale: 'es',
							},"placeholder":"Agregar fecha","disabled":_vm.isInventory ? !_vm.specimen.check : false},model:{value:(_vm.specimen.date_status),callback:function ($$v) {_vm.$set(_vm.specimen, "date_status", $$v)},expression:"specimen.date_status"}})],1)],1),(_vm.isEditingStatusSpecimen)?_c('b-col',{staticClass:"my-1 d-flex justify-content-end",attrs:{"cols":"12","md":"6"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('save-status')}}},[_vm._v("Guardar")]),(_vm.isEditingAdmin)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$emit('cancel-editing')}}},[_vm._v(" Cancelar ")]):_vm._e()],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }