<template>
	<b-form class="w-100">
		<b-row class="card mb-0">
			<!-- {{ specimen.new_status_id }} -->
			<b-col cols="12" md="12" :disabled="true">
				<b-row>
					<b-col cols="12" :md="isCreatingNewSpecimen ? 12 : 6">
						<b-form-group label-class="label-size" label="ESTADO" label-for="estado">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									id="estado"
									v-model="specimen.new_status_id"
									:clearable="false"
									label="description"
									:options="arrayStatus"
									:reduce="(option) => option.id"
									:class="{ 'border-danger rounded': errors[0] }"
									placeholder="Agregar estado"
									:disabled="isInventory ? !specimen.check : false"
									:selectable="(opt) => opt.selectable"
								/>
								<span style="color: red">{{ errors[0] }}</span>
							</ValidationProvider>
						</b-form-group>
					</b-col>
					<!-- MUERTO -->
					<template v-if="specimen.new_status_id == 3 || specimen.new_is_reason == 1">
						<b-col cols="12" md="12">
							<b-form-group id="input-group-1" label="MOTIVO" label-for="input-1">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-select
										v-model="specimen.reason_id"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="arrayReasons"
										:clearable="false"
										label="reason"
										:reduce="(option) => option.id"
										:class="{ 'border-danger rounded': errors[0] }"
										placeholder="Seleccione..."
										@input="
											() => {
												arrayDeads[0].others_description = ''
											}
										"
										:disabled="isInventory ? !specimen.check : false"
									>
										<template v-slot:option="option">
											<div class="d-flex justify-content-between align-items-center">
												<span :style="`margin-left: ${option.parent_id ? '10' : '0'}px`">
													{{ option.reason }}
												</span>
												<span v-if="option.parent_id">
													Sub-motivo de {{ option.parent_reason }}
												</span>
											</div>
										</template>
									</v-select>
									<span style="color: red">{{ errors[0] }}</span>
								</ValidationProvider>
							</b-form-group>
							<b-form-group label="OTROS" label-for="others_description" v-if="specimen.reason === 19">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-input-group label-for="others_description">
										<b-form-textarea
											id="others_description"
											v-model="arrayDeads[0].others_description"
											type="text"
											placeholder="Ingrese otra razon"
											:state="errors[0] ? false : null"
										></b-form-textarea>
									</b-input-group>
									<span style="color: red">{{ errors[0] }}</span>
								</ValidationProvider>
							</b-form-group>
						</b-col>

						<b-col cols="12" md="12" v-if="specimen.new_status_id == 3">
							<b-form-group id="input-group-1" label="EVIDENCIAS" label-for="input-1">
								<drag-and-drop-images
									v-model="specimen.evidences"
									:filesArray="specimen.evidences"
									:source="`flyers`"
									:containerTitle="`Arrastra las evidencias aquí`"
									:containerDescription="`Selecciona uno`"
									containerPadding="10px"
								></drag-and-drop-images>
							</b-form-group>
						</b-col>
					</template>

					<!-- ENFERMO -->
					<template v-if="specimen.new_status_id == 6 || specimen.new_is_reason">
						<b-col cols="12" md="6">
							<!-- <b-form-group id="input-group-1" label="MOTIVO" label-for="input-1">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-select
										v-model="specimen.disease_id"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="arrayDiseases"
										:clearable="false"
										label="name"
										:reduce="(option) => option.id"
										:class="{ 'border-danger rounded': errors[0] }"
										placeholder="Agregar razon"
										@input="
											() => {
												specimen.others_description = ''
											}
										"
										:disabled="isInventory ? !specimen.check : false"
									></v-select>
									<span style="color: red">{{ errors[0] }}</span>
								</ValidationProvider>
							</b-form-group> -->
							<b-form-group label="OTROS" label-for="others_description" v-if="specimen.disease === 1">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-input-group label-for="others_description">
										<b-form-textarea
											id="others_description"
											v-model="specimen.others_description"
											type="text"
											placeholder="Ingrese otra razon"
											:state="errors[0] ? false : null"
										></b-form-textarea>
									</b-input-group>
									<span style="color: red">{{ errors[0] }}</span>
								</ValidationProvider>
							</b-form-group>
						</b-col>
					</template>

					<!-- Preparacion -->
					<template>
						<b-col cols="12" md="6" v-if="specimen.new_status_id == 9 || specimen.new_status_id == 10">
							<b-form-group label-class="label-size" label="PESO KG" label-for="weight">
								<ValidationProvider v-slot="{ errors }" rules="required">
									<b-input-group append="£" label-for="weight">
										<b-form-input
											id="weight"
											v-model="specimen.weight"
											type="number"
											placeholder="0"
											:state="errors[0] ? false : null"
											:disabled="isInventory ? !specimen.check : false"
										></b-form-input>
									</b-input-group>
									<span style="color: red">{{ errors[0] }}</span>
								</ValidationProvider>
							</b-form-group>
						</b-col>
						<b-col
							cols="12"
							:md="[9, 10].includes(specimen.new_status_id) ? '6' : '12'"
							v-if="[6, 9, 10, 12, 13].includes(specimen.new_status_id)"
						>
							<b-form-group label-class="label-size" label="RESPONSABLE" label-for="responsable">
								<ValidationProvider
									:rules="specimen.new_status_id == 10 ? 'required' : ''"
									v-slot="{ errors }"
								>
									<v-select
										id="responsable"
										v-model="specimen.user_id"
										:clearable="false"
										label="name"
										:options="arrayUsers"
										:reduce="(option) => option.id"
										:class="{ 'border-danger rounded': errors[0] }"
										placeholder="Agregar responsable"
										:disabled="isInventory ? !specimen.check : false"
									/>
									<span style="color: red">{{ errors[0] }}</span>
								</ValidationProvider>
							</b-form-group>
						</b-col>
					</template>

					<!-- DONADO O VENDIDO  o PRESTADO-->

					<b-col cols="12" md="6" v-if="[2, 4, 5, 11].includes(specimen.new_status_id)">
						<b-form-group label-class="label-size" label="CLIETE O BENEFICIARIO" label-for="client">
							<v-select
								id="client"
								v-model="specimen.client_id"
								:clearable="false"
								label="name"
								:options="arrayClients"
								:reduce="(option) => option.id"
								:disabled="isInventory ? !specimen.check : false"
							/>
						</b-form-group>
					</b-col>

					<!-- VENDIDO -->
					<b-col cols="12" md="6" v-if="[2].includes(specimen.new_status_id)">
						<b-form-group label-class="label-size" label="PRECIO" label-for="price">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-form-input
									id="price"
									v-model="specimen.price"
									label="name"
									type="text"
									placeholder="0"
									@keyup="justNumbersToFront(specimen)"
									@blur="fixedCost(specimen)"
									:state="errors[0] ? false : null"
									:disabled="isInventory ? !specimen.check : false"
								/>
								<span style="color: red">{{ errors[0] }}</span>
							</ValidationProvider>
						</b-form-group>
					</b-col>

					<!-- VARIADO -->
					<template v-if="!isCreatingNewSpecimen || specimen.new_status_id !== 1">
						<b-col v-if="specimen.status !== 3 && specimen.disease !== 1" cols="12" md="12">
							<b-form-group label-class="label-size" label="DESCRIPCION" label-for="description">
								<b-input-group label-for="description">
									<b-form-textarea
										id="description"
										v-model="specimen.description"
										type="text"
										placeholder="Agregar descripcion"
										:disabled="isInventory ? !specimen.check : false"
									></b-form-textarea>
								</b-input-group>
							</b-form-group>
						</b-col>
					</template>
					<b-col cols="12" :md="isCreatingNewSpecimen ? 12 : 6">
						<b-form-group label-class="label-size" label="FECHA" label-for="date">
							<flat-pickr
								id="date"
								v-model="specimen.date_status"
								class="form-control bg-transparent"
								:config="{
									locale: 'es',
								}"
								placeholder="Agregar fecha"
								:disabled="isInventory ? !specimen.check : false"
							/>
						</b-form-group>
					</b-col>

					<b-col cols="12" md="6" v-if="isEditingStatusSpecimen" class="my-1 d-flex justify-content-end">
						<b-button variant="primary" @click="$emit('save-status')">Guardar</b-button>
						<b-button
							class="ml-1"
							variant="secondary"
							v-if="isEditingAdmin"
							@click="$emit('cancel-editing')"
						>
							Cancelar
						</b-button>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</b-form>
</template>

<script>
import DragAndDropImages from "@/components/commons/drag-and-drop/DragAndDropImages.vue"
import vSelect from "vue-select"
import flatPickr from "vue-flatpickr-component"
import specimen from "@/store/modules/inventory/specimen"
// import { es } from "flatpickr/dist/l10n/es.js";
export default {
	name: "SpecimentStatusChangedBody",
	components: {
		vSelect,
		DragAndDropImages,
		flatPickr,
	},
	props: {
		specimen: {
			type: Object,
			required: true,
		},
		arrayDeads: {
			type: Array,
			required: true,
		},
		arrayReasons: {
			type: Array,
			required: true,
		},
		arrayStatus: {
			type: Array,
			required: true,
		},
		arrayDiseases: {
			type: Array,
			required: true,
		},
		arrayUsers: {
			type: Array,
			required: true,
		},
		arrayClients: {
			type: Array,
			required: true,
		},
		isEditingAdmin: {
			type: Boolean,
			required: true,
		},
		isEditingStatusSpecimen: { type: Boolean, default: true },
		isCreatingNewSpecimen: { type: Boolean, default: false },
		isInventory: {
			type: Boolean,
		},
	},
	methods: {
		justNumbersToFront(specimen) {
			if (specimen.price) {
				specimen.price = this.justNumbers(specimen.price)
			}
		},
		fixedCost(specimen) {
			if (specimen.price && Number(specimen.price)) {
				specimen.price = this.fixedMoney(specimen.price)
			}
		},
	},
}
</script>

<style lang="scss">
.container {
	// background: green;
	max-width: 1540px !important;
}
</style>
